.overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin multiLineEllipsis($fontSize, $lineHeight, $lineCount) {
  font-size: $fontSize;
  line-height: $lineHeight;
  overflow: hidden;
  display: box;
  display: -webkit-box;
  -webkit-line-clamp: $lineCount;
  box-orient: vertical;
  -webkit-box-orient: vertical;
}

@mixin flex($justify, $align) {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: $justify;
  justify-content: $justify;
  -webkit-align-items: $align;
  align-items: $align;
}

@mixin fixed-dialog($justify, $align) {
  @include flex($justify, $align);
  width: calc(100% - 40px);
  height: 100%;
  padding: 0px 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3000;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: fadeIn .4s;
  animation: fadeIn .4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  @-webkit-keyframes fadeIn {
      from {
          opacity: 0;
      }
      to {
          opacity: 1.0;
      }
  }
  
  @keyframes fadeIn {
      from {
          opacity: 0;
      }
      to {
          opacity: 1.0;
      }
  }
}

@mixin animationZoom{
  animation-name: error-notice-scale;
  animation-duration: 0.2s;
  animation-iteration-count: 2;
  -webkit-animation-name: error-notice-scale;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-iteration-count: 2;
  @keyframes error-notice-scale {
      0% {
          transform: scale(1);
      }
      50% {
          transform: scale(1.3);
      }
      100% {
          transform: scale(1);
      }
  }
  @-webkit-keyframes error-notice-scale {
      0% {
          transform: scale(1);
      }
      50% {
          transform: scale(1.3);
      }
      100% {
          transform: scale(1);
      }
  }
}

@mixin zig-zag($height, $backgroundColor) {
  height: $height;
position: relative;
  z-index: 1;
  background: $backgroundColor;
&:before {
      content: "";
      height: $height;
  display: block;
  position: absolute;
  left: 0;
    right: 0;
  top: 0;
      background: linear-gradient(-55deg, $mainBackgroundColor calc(#{$height} / 2), transparent 0) 0 calc(#{$height} / 2),
                  linear-gradient( 55deg, $mainBackgroundColor calc(#{$height} / 2), transparent 0) 0 calc(#{$height} / 2);
  background-position: top left;
  background-repeat: repeat-x;
  background-size: $height $height;
}
}

@mixin banner-zig-zag($height, $backgroundColor) {
  @include zig-zag($height, $backgroundColor);
  margin-top: calc(-#{$height} + 1px);
}

@mixin delete-button($backgroundColor, $color) {
  .delete-button {
      display: block;
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      border: 4px solid $backgroundColor;
      border-radius: 100%;
      background: 
          linear-gradient(-45deg,transparent,transparent 40%,$color 0,$color 60%,transparent 0,transparent), 
          linear-gradient(45deg,transparent,transparent 40%,$color 0,$color 60%,transparent 0,transparent),
          -webkit-linear-gradient(-45deg,transparent,transparent 40%,$color 0,$color 60%,transparent 0,transparent), 
          -webkit-linear-gradient(45deg,transparent,transparent 40%,$color 0,$color 60%,transparent 0,transparent);
      background-color: $backgroundColor;
      cursor: pointer;
      &.hide {
          display: none;
      }
  }
}

$mainBackgroundColor: #1B1B1B;
$soldoutOverlayColor: rgba(27, 27, 27, 0.4);

$grayColors: (
  grayTextOnWhite: #575757,
  grayTextOnBlack: #ABABAB,
  darkGrayTextOnGray: #58595B,
  lightGrayElementOnWhite: #D0D2D3,
  lightGrayElementOnBlack: #A0A0A0,
  midGrayElementOnBlack: #707070,
  darkGrayElementOnBlack: #535353,
  filterBorderGray: #333333,
  imageBorder: #e2e2e2,
  subItemOnBlack: #AEAEAE,
  modifierOnBlack: #7E7E7E
);

$whiteColor: #ffffff;
$blackColor: #000000;
$greenColor: #1AAD19;
$darkCyanColor: #25A7AC;
$redColor: #FF3636;
$yellowColor: #FFFF00;
$lightPinkColor: #FCD9DE;
$darkPinkColor: #cc3367;
$lightBlueColor: #f2f8ff;

$navigationBarHeight: 56px;
$searchHeight: 40px;
$iconSliderHeight: 125px;
$noticeMessageHeight: 25px;
$headerHeight: 154px;
$headerHeightNoSubtitle: 124px;
$bottomButtonHeight: 60px;
$quantityWrapperHeight: 82px;
$bannerHeight: calc(100vw / 720 * 320);
$bannerZigZagHeight: calc(100vw / 720 * 30);

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-smoothing: antialiased;
}