@import './globalVariables.scss';

.slideshow-preview-dialog {
  .each-slide {
    @include flex(center, center);
    height: 100%;
  }

  .image {
    width: 500px;
  }

  .movie-wrapper {
    height: 100%;
    @include flex(center, center);
  }

  .movie {
    @include flex(center, center);
    font-size: 100px;
    color: map-get($grayColors, lightGrayElementOnBlack);
  }
}