@import './globalVariables.scss';

.rc-dropdown-menu > .rc-dropdown-menu-item-selected:after {
  @extend .material-icons;
  content: 'done';
  font-weight: bold;
  position: absolute;
  top: 6px;
  right: 16px;
  font-size: 18px;
  color: #3CB8F0;
}

.slideshow {
  scroll-behavior: smooth;

  .sub-title {
    margin-bottom: 16px;
  }

  .advertisement {
    padding: 0 16px 16px 16px;
  }

  .details-wrapper {
    padding: 16px;
    background-color: $lightBlueColor;

    .details {      
      .row {
          @include flex(center, center);
          margin: 8px 0;
      }
    
      .label {
          flex: 2;
      }
    
      .content {
          flex: 8;            
      }
    }

    .preview {
      margin-bottom: 16px;
    }

    .media {
      margin-bottom: 16px;
    }
  }

  .content {
    padding: 16px;

    .content-title {
      margin-bottom: 16px;
    }
  }

  .error {
    color: $redColor;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 5px;
  }
}
