@import './globalVariables.scss';

.media-library-dialog {
  .dropzone {
    width: 100%;
    height: 80px; 
    background: #f2f2f2;
    cursor: pointer;
    box-sizing: content-box;
    @include flex(center, center);

    .dropzone-message {
      color: map-get($grayColors, lightGrayElementOnBlack)
    }
  }

  .preview {
    @include flex(center, center);
    margin: 16px;
  }

}