@import './globalVariables.scss';

.slideshow-template {
    .template-auto-complete {
        margin-bottom: 16px;
    }

    .info {
        margin-bottom: 16px;
        padding: 16px;
    }
   
    .details {
        .row {
            @include flex(center, center);
            margin: 8px 0;
        }

        .label {
            flex: 2;
        }

        .content {
            flex: 8;            
        }
    }

    .error {
        color: $redColor;
        font-size: 16px;
        line-height: 30px;
        text-align: left;
        margin-bottom: 5px;
    }

    .save-btn {
        margin-right: 16px;
    }

    .create-btn {
        margin-right: 16px;
    }
}
