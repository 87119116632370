@import './globalVariables.scss';

.slideshow-preview {

  .error {
    color: $redColor;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 5px;
  }

  .movie-wrapper {
    height: 100%;
    @include flex(center, center);
  }

  .movie {
    @include flex(center, center);
    font-size: 100px;
    color: map-get($grayColors, lightGrayElementOnBlack);
  }

  .image {
    width: 100%;
  }
}