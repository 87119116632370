@import './globalVariables.scss';

.slideshow-media-list {
    .title {
        font-size: 14px;
    }
    
    .card {
        display: flex;
        margin-bottom: 8px;
        justify-content: space-between;
        padding: 15px;
    }

    .details {
        display: flex;
        flex-direction: column;
        flex: 2 1;
    }

    .content {
        flex: 1;
    }

    .cardMediaStyle {
        background-size: contain;
        width: 100%;
    }

    .imageWrapper {
        flex: '0';

        .image {
            max-width: 400px;
            min-width: 400px;
            border-radius: 15px;
        }
    }

    .delete-icon {
        i {
            font-size: 50px;
            color: map-get($grayColors, lightGrayElementOnBlack);
        }
    }
}
